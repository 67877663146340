import "./Navbar.css";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const topics = [
    "Tech",
    "Cars",
    "Art",
    "Sports",
    "Finance",
    "Business",
    "Corporate",
    "Video",
    "Gaming",
];

function Navbar(props) {
    const navigate = useNavigate();
    const [term, setTerm] = useState("");
    const [filters, setFilters] = useState([]);
    const [showDropdown, setDropdown] = useState(false);
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const location = useLocation();

    const handleLogout = () => {
        document.cookie = "";
        props.setUser(null);
    };

    const toggleUserDropdown = () => {
        setShowUserDropdown(!showUserDropdown);
    };

    const modifyFilters = (e) => {
        if (e.target.checked) {
            setFilters([...filters, e.target.value]);
        } else {
            const copyOfFilters = filters.filter(
                (val) => val !== e.target.vaulue
            );

            setFilters(copyOfFilters);
        }
    };

    const setNavigate = () => {
        let endpoint = "/";
        if (term.length) {
            endpoint += `?q=${term}`;
        }
        if (term.length && filters.length) {
            endpoint += `&filter=${filters}`;
        }

        if (!term.length && filters.length) {
            endpoint += `?filter=${filters}`;
        }

        return endpoint;
    };

    const isActive = (topic) => props.topic === topic;

    const isTopic = (location) =>
        location &&
        location !== "register" &&
        location !== "login" &&
        !location.includes("post") &&
        !location.includes("user");

    const getAllChildren = (element) => {
        if (!element) {
            return [];
        }
        let elements = [element];

        for (const child of element.children) {
            elements = [...elements, ...getAllChildren(child)];
        }

        return elements;
    };

    const excludePaths = ["register", "login", "post", "logout", "user"];

    const pathIsTopic = (path) => {
        for (const exclude of excludePaths) {
            if (path.includes(exclude)) return false;
        }
        return true;
    };

    useEffect(() => {
        document.addEventListener("click", (e) => {
            const elements = getAllChildren(
                document.querySelector("#search-bar")
            );
            if (elements.includes(e.target)) {
                setDropdown(true);
            } else {
                setDropdown(false);
            }
        });
    }, []);

    useEffect(() => {
        const path = location.pathname.replaceAll("/", "");
        if (pathIsTopic(path)) {
            props.setTopic(path);
        } else {
            props.setTopic("");
        }
    }, [location]);

    return (
        <div className="Navbar p-3">
            <div className="row">
                <div className="col-md-3">
                    <div className="d-inline-block logo-holder">
                        {props.topic || isTopic(location.pathname.slice(1)) ? (
                            <Link to={`/${props.topic}` || location.pathname}>
                                <h1 className="d-inline-block mr-auto">
                                    Spark Nexus
                                </h1>
                                <span className="nav-topic">
                                    {props.topic || location.pathname.slice(1)}
                                </span>
                            </Link>
                        ) : (
                            <Link to="/">
                                <h1 className="d-inline-block mr-auto">
                                    Spark Nexus
                                </h1>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="col-md-7">
                    <div id="search-bar">
                        <input
                            className={
                                showDropdown
                                    ? "form form-control d-inline-block search-bar w-100 drop-on"
                                    : "form form-control d-inline-block search-bar w-100"
                            }
                            placeholder="Search for a particular problem"
                            value={term}
                            onChange={(e) => setTerm(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    navigate(setNavigate());
                                }
                            }}
                        ></input>
                        <div className="search-btn-box">
                            <i
                                className="fa-solid fa-magnifying-glass search-btn"
                                onClick={() => navigate(setNavigate())}
                            ></i>
                        </div>
                        <div
                            className={
                                showDropdown ? "search-bar-drop-down" : "d-none"
                            }
                        >
                            <strong className="drop-down-title">Filters</strong>
                            <br />
                            <div id="filters">
                                <div className="filter-column">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            name="Animals & Pets"
                                            value="pets"
                                            className="checkbox"
                                            onChange={(e) => modifyFilters(e)}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="inlineCheckbox2"
                                        >
                                            Animals & Pets
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            name="AI"
                                            value="ai"
                                            className="checkbox"
                                            onChange={(e) => modifyFilters(e)}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="inlineCheckbox2"
                                        >
                                            AI
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            value="fashion"
                                            name="Apparel/Fashion"
                                            className="checkbox"
                                            onChange={(e) => modifyFilters(e)}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="inlineCheckbox2"
                                        >
                                            Apparel/Fashion
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            value="art"
                                            name="Arts & Entertainment"
                                            className="checkbox"
                                            onChange={(e) => modifyFilters(e)}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="inlineCheckbox2"
                                        >
                                            Arts & Entertainment
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            value="tech"
                                            name="Tech"
                                            className="checkbox"
                                            onChange={(e) => modifyFilters(e)}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="inlineCheckbox1"
                                        >
                                            Tech
                                        </label>
                                    </div>
                                </div>
                                <div className="filter-column">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            value="law"
                                            name="Legal Services"
                                            className="checkbox"
                                            onChange={(e) => modifyFilters(e)}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="inlineCheckbox3"
                                        >
                                            Legal Services
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            value="medical"
                                            name="Medical"
                                            className="checkbox"
                                            onChange={(e) => modifyFilters(e)}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="inlineCheckbox3"
                                        >
                                            Medical
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            value="health"
                                            name="Health & Fitness"
                                            className="checkbox"
                                            onChange={(e) => modifyFilters(e)}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="inlineCheckbox2"
                                        >
                                            Health & Fitness
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            value="sports"
                                            name="Sports"
                                            className="checkbox"
                                            onChange={(e) => modifyFilters(e)}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="inlineCheckbox2"
                                        >
                                            Sports
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            value="electronics"
                                            name="Electronics"
                                            className="checkbox"
                                            onChange={(e) => modifyFilters(e)}
                                        />
                                        <label
                                            className="form-check-label"
                                            for="inlineCheckbox2"
                                        >
                                            Electronics
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
            <div className="nav-user">
                {props.user ? (
                    <div className="user-dropdown">
                        <div
                            className="user-card-toggle"
                            onClick={toggleUserDropdown}
                        >
                            <i className="fas fa-user-circle"></i>
                        </div>
                        {showUserDropdown && (
                            <div className="user-card-dropdown-menu">
                                {/* Header Section */}
                                <div className="user-card-header">
                                    {props.user.photo ? (
                                        <img
                                            className="user-drop-photo"
                                            src={props.user.photo}
                                            onError={() => {
                                                props.setUser({
                                                    ...props.user,
                                                    photo: "https://www.svgrepo.com/show/327465/person-circle.svg",
                                                });
                                            }}
                                            alt="User"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <h2>{props.user.username}</h2>
                                    <p>{props.user.email}</p>
                                </div>

                                {/* Main Actions Section */}
                                <div className="user-card-section">
                                    <Link
                                        to={`/user/${props.user.username}`}
                                        className="user-card-mini-button"
                                    >
                                        <i className="fa fa-user"></i> Profile
                                    </Link>
                                    <span
                                        className="user-card-mini-button"
                                        onClick={handleLogout}
                                    >
                                        <i className="fa fa-sign-out"></i> Sign
                                        Out
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <Link
                        to="/login"
                        className="btn btn-outline-primary sign-in-btn"
                    >
                        Sign In
                    </Link>
                )}
            </div>
            <div className="row nav-link-holder">
                <div
                    className={
                        isActive("")
                            ? "nav-link-active nav-link-wrapper"
                            : "nav-link-wrapper"
                    }
                >
                    <Link className="nav-link" to="/">
                        Home
                    </Link>
                </div>
                {topics.map((topic) => (
                    <div
                        className={
                            isActive(topic.toLowerCase())
                                ? "nav-link-active nav-link-wrapper"
                                : "nav-link-wrapper"
                        }
                    >
                        <Link
                            className="nav-link"
                            to={`/${topic.toLowerCase()}`}
                        >
                            {topic}
                        </Link>
                    </div>
                ))}
            </div>
            <hr />
        </div>
    );
}

export default Navbar;
