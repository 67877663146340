import "./Post.css";
import React, { useState, useEffect } from "react";
import Comment from "./Comment/Comment";
import Carousel from "react-bootstrap/Carousel";
import Respond from "./Respond/Respond";
import UserCard from "../UserCard/UserCard";
import Solution from "./Solution/Solution";
import { useParams, Link } from "react-router-dom";

const Post = (props) => {
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState(null);
    const [showReplies, setReplies] = useState({});
    const [solutions, setSolutions] = useState(null);
    const [next, setNext] = useState([]);
    const [postTopics, setPostTopics] = useState([]);

    const params = useParams();

    const getDate = (date) => {
        if (!date) {
            return "";
        }

        const shortMonthName = new Intl.DateTimeFormat("en-US", {
            month: "short",
        }).format;
        date = new Date(date);
        const shortName = shortMonthName(date); // "Jul"

        return `${shortName} ${date.getDay() + 1}, ${date.getFullYear()}`;
    };

    const fetchComments = async () => {
        await fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/comments/post/` + params.postId,
            {
                credentials: "include",
                method: "GET",
            }
        )
            .then((response) => {
                if (response.status === 404) {
                    return;
                }
                return response.json();
            })
            .then((data) => {
                setComments(data);
            });
    };

    const fetchNext = async () => {
        const res = await fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/posts/listtest?limit=5`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                // mode: "cors",
                body: JSON.stringify({
                    includeIndustries: [],
                    excludeIndustries: [],
                    excludePosts: [params.postId],
                    user: props.user ? props.user.username : "",
                }),
            }
        );

        const json = await res.json();
        const { posts } = json;
        setNext(posts);
    };

    const fetchNextContent = (post) => {
        if (!post) return <></>;
        if (post.images && post.images[0] && post.images[0].src) {
            return (
                <div className="text-center">
                    <div className="overlay"></div>
                    <img src={post.images[0].src} alt="carousel-img"></img>
                </div>
            );
        }
        return (
            <div className="next-content-carousel-item">
                <div className="overlay"></div>
                <div
                    className="see-more-content text-center"
                    dangerouslySetInnerHTML={{
                        __html: post.content,
                    }}
                ></div>
            </div>
        );
    };

    useEffect(() => {
        // Get Post
        if (!post || post._id !== params.postId) {
            window.scrollTo({ top: 0, behavior: "instant" });
            setComments(null);
            setNext([]);
            fetch(
                `${
                    process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                }/posts/` + params.postId,
                {
                    credentials: "include",
                }
            )
                .then((response) => {
                    if (response.status === 404) {
                        return;
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data) {
                        setPost(data);
                        setSolutions(data.solutions);
                        fetchComments();
                        if (data.topics) {
                            props.setTopic(data.topics[0]);
                            setPostTopics(data.topics);
                        }
                        fetchNext();
                    }
                });
        }
    }, [params.postId]);

    return (
        <div className="Post">
            {post ? (
                <div className="row">
                    <div
                        className={
                            solutions && solutions.length
                                ? "p-3 col-12 col-md-9"
                                : "p-3 col-12"
                        }
                    >
                        <div className="lead-wrapper">
                            <h1 className="post-title">{post.title}</h1>
                            <span className="post-time">
                                {post.updateTime || post.createTime
                                    ? "Updated " + getDate(post.updateTime) ||
                                      getDate(post.createTime)
                                    : ""}
                            </span>
                            <Link
                                id="solution-btn"
                                className="create-post-btn"
                                to="/post/new"
                                state={{
                                    parent: params.postId,
                                    parentTitle: post.title,
                                }}
                            >
                                + Write Solution
                            </Link>
                            <p className="mt-2">
                                {postTopics && postTopics.length ? (
                                    postTopics
                                        .filter((topic) => topic != null)
                                        .map((topic) => (
                                            <span className="post-solution-badge">
                                                {topic}
                                            </span>
                                        ))
                                        .slice(0, 3)
                                ) : (
                                    <></>
                                )}
                            </p>
                            <br></br>
                            <UserCard
                                img={
                                    props.author?.photo ||
                                    "https://www.svgrepo.com/show/327465/person-circle.svg"
                                }
                                headline={
                                    props.author?.headline ||
                                    "Data Scientist @ Amazon"
                                }
                                username={post.author?.username}
                                company={post.author?.company}
                            />
                            <hr></hr>
                            {post.images && post.images[0] ? (
                                <div className="image-holder">
                                    <img
                                        className="image-asset"
                                        src={post.images[0].url}
                                        alt="post thumbnail"
                                    ></img>
                                    <p className="image-information">
                                        <b className="image-source">
                                            {post.images[0].source}
                                        </b>{" "}
                                        <span className="image-caption">
                                            {post.images[0].caption}
                                        </span>
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}
                            <p
                                className="content-text"
                                dangerouslySetInnerHTML={{
                                    __html: post.content,
                                }}
                            ></p>
                        </div>
                    </div>
                    <div className="col-md-3 mt-3 solution-container">
                        <Solution
                            postId={params.postId}
                            solutions={solutions}
                        ></Solution>
                    </div>
                    <div className="see-more-wrapper">
                        <h3 className="post-title">See More</h3>
                        <hr></hr>
                        <Carousel>
                            {next.map((post) => (
                                <Carousel.Item>
                                    {fetchNextContent(post)}
                                    <Carousel.Caption>
                                        <h3>
                                            <Link
                                                to={`/post/${post._id}`}
                                                className="carousel-link"
                                            >
                                                {post.title}
                                            </Link>
                                        </h3>
                                        <p>by {post.author.username}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    <div className="comments-wrapper">
                        {comments ? (
                            <>
                                <hr></hr>
                                <h1 className="post-title">Comments</h1>
                            </>
                        ) : (
                            <></>
                        )}
                        <div>
                            {props.user ? (
                                <Respond
                                    postId={params.postId}
                                    fetchComments={fetchComments}
                                    user={props.user}
                                />
                            ) : (
                                <></>
                            )}
                            <Comment
                                fetchComments={fetchComments}
                                showReplies={showReplies}
                                setReplies={setReplies}
                                comments={comments}
                                user={props.user}
                            ></Comment>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="jumbotron text-left mt-5">
                    <h1 className="display-1 mt-5">Oops!</h1>
                    <p className="lead">That page doesn't seem to exist</p>
                </div>
            )}
        </div>
    );
};

export default Post;
