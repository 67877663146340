import "./NewPost.css";
import React, { useState } from "react";
import Editor from "./Editor/Editor";
import { Link, useNavigate, useLocation } from "react-router-dom";

function NewPost(props) {
    let location = useLocation();
    const [title, setTitle] = useState("");
    const [topics, setTopics] = useState(new Set());
    const [images, setImages] = useState([]);
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");
    const [content, setContent] = useState("");

    if (!location || !location.state) {
        location = {
            state: {
                parent: "",
                parentTitle: "",
            },
        };
    }

    const { parent, parentTitle } = location?.state;
    const navigate = useNavigate();

    const handleSubmit = async () => {
        console.log("parent", parent);
        if (!props.user) return;
        if (message === "Post successfully created!") return;

        const body = {
            title,
            topics: Array.from(topics),
            images,
            content,
            author: props.user._id,
        };

        if (body.topics.length) {
            body.tags = body.topics.map((topic) => `industry:${topic}`);
        }

        if (parent) {
            body.parent = parent;
        }

        fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/posts/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(body),
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        "Post failed to be created, please double-check the post or contact support"
                    );
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                setStatus("success");
                setMessage("Post successfully created!");
                setTimeout(() => {
                    setMessage("");
                    setStatus("");
                    return navigate("/");
                }, 3000);
            })
            .catch((error) => {
                setMessage(error.message);
                setStatus("danger");
                setTimeout(() => {
                    setMessage("");
                    setStatus("");
                }, 5000);
            });
    };

    return (
        <div className="NewPost p-3">
            <div id="editor-nav">
                <Link to="/">
                    <h1 className="d-inline-block mr-auto nav-title">
                        Spark Nexus
                    </h1>
                </Link>
                {parentTitle ? (
                    <span className="solution-title-text">
                        Writing a solution for{" "}
                        <Link to={`/post/${parent}`}>{parentTitle}</Link>
                    </span>
                ) : (
                    <></>
                )}
                <button
                    className="btn btn-outline-primary m-1 publish-btn"
                    onClick={handleSubmit}
                >
                    Publish
                </button>
                <hr></hr>
            </div>
            <Editor
                setContent={setContent}
                setTitle={setTitle}
                setTopics={setTopics}
                setImages={setImages}
                topics={topics}
                title={title}
                user={props.user}
            ></Editor>
            {message ? (
                <div
                    className={`post-message alert alert-${status}`}
                    role="alert"
                >
                    {message}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

export default NewPost;
