import "./Login.css";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Login(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState("");
    const [statusColor, setStatusColor] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/auth/login`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                // mode: "cors",
                body: JSON.stringify({ username, password }),
            }
        )
            .then((response) => {
                if (response.status === 201) {
                    setStatusColor("success");
                } else {
                    setStatusColor("danger");
                }
                return response.json();
            })
            .then((data) => {
                if (data.message) {
                    setStatus(data.message);
                }
                if (data._id) {
                    props.setUser({
                        _id: data._id,
                        username: data.username,
                        label: data.label,
                        company: data.company,
                    });
                    setTimeout(() => {
                        setStatus("");
                        return navigate("/");
                    }, 1500);
                } else {
                    setTimeout(() => {
                        setStatus("");
                    }, 3000);
                }
            });
    };
    return (
        <div id="login-holder">
            <div className="Login p-3">
                <div className="text-center login-box">
                    <span className="login-text">Login</span>
                </div>
                <form className="form-signin" onSubmit={(e) => handleSubmit(e)}>
                    <label className="username-label">Username</label>
                    <input
                        type="username"
                        id="inputUsername"
                        className="form-control mb-2 font-awesome"
                        placeholder="&#xf007; Type your username"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        required=""
                        autoFocus=""
                    />
                    <br></br>
                    <label className="username-label">Password</label>
                    <input
                        type="password"
                        id="inputPassword"
                        className="form-control font-awesome"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        placeholder="&#xf023; Type your password"
                        required=""
                    />
                    <br></br>
                    <div className="row">
                        <div className="col-md-12">
                            <button
                                className="btn btn-lg btn-primary btn-block login-btn"
                                type="submit"
                            >
                                LOGIN
                            </button>
                        </div>
                    </div>
                    <br></br>
                    {statusColor ? (
                        <div className={`alert alert-${statusColor}`}>
                            {status}
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="sign-up-container col-12 text-center">
                        <div className="d-inline-block">
                            Don't have an account?
                            <br></br>
                            <br></br>
                            <Link to="/register" className="sign-up-btn">
                                SIGNUP
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
