import React, { useState, useEffect } from "react";
import "./Toolbar.css";
import { toggleMark } from "prosemirror-commands";
import { useEditorEventCallback } from "@nytimes/react-prosemirror";

const industries = [
    "sports",
    "tech",
    "art",
    "cars",
    "finance",
    "business",
    "corporate",
    "videogames",
];

const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    // Options for formatting the date
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true, // Use 12-hour clock with AM/PM
    };

    // Use Intl.DateTimeFormat to format the date
    return new Intl.DateTimeFormat("en-US", options).format(date);
};

const Toolbar = (props) => {
    const bold = useEditorEventCallback((view) => {
        const toggleBoldMark = toggleMark(view.state.schema.marks.strong);
        toggleBoldMark(view.state, view.dispatch, view);
        const boldIsOn =
            view.state.storedMarks &&
            view.state.storedMarks.filter((doc) => doc.type.name === "strong")
                .length > 0;
        updateMarks("strong", boldIsOn);
    });

    const italic = useEditorEventCallback((view) => {
        const toggleItalicMark = toggleMark(view.state.schema.marks.italic);
        toggleItalicMark(view.state, view.dispatch, view);
        const italicIsOn =
            view.state.storedMarks &&
            view.state.storedMarks.filter((doc) => doc.type.name === "italic")
                .length > 0;
        updateMarks("italic", italicIsOn);
    });

    const code = useEditorEventCallback((view) => {
        const toggleCodeMark = toggleMark(view.state.schema.marks.code);
        toggleCodeMark(view.state, view.dispatch, view);
        const codeIsOn =
            view.state.storedMarks.filter((doc) => doc.type.name === "code")
                .length > 0;
        updateMarks("code", codeIsOn);
    });

    const [currentFontSize, setCurrentFontSize] = useState("24px");
    const [activeBtns, setActive] = useState({});
    const [imgUrl, setUrl] = useState("");
    const [embedHTML, setEmbedHTML] = useState("");
    const [imgCaption, setCaption] = useState("");
    const [linkUrl, setLinkUrl] = useState("");
    const [linkTitle, setLinkTitle] = useState("");
    const [imgSource, setSource] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [linkModalIsOpen, setLinkModalIsOpen] = useState(false);
    const [embedModalIsOpen, setEmbedModalIsOpen] = useState(false);

    const isValidUrl = (url) =>
        url.match(
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        );

    const updateMarks = (mark, isOn) => {
        const copyOfPrevious = activeBtns ? { ...activeBtns } : {};
        if (isOn) {
            copyOfPrevious[mark] = true;
        } else {
            copyOfPrevious[mark] = false;
        }
        setActive(copyOfPrevious);
    };

    const openImageModal = () => {
        setModalIsOpen(true);
    };

    const addImage = () => {
        if (isValidUrl(imgUrl)) {
            props.insertImage(imgUrl, imgCaption, imgSource);
        }
        setModalIsOpen(false);
    };

    const insertEmbed = () => {
        // check if embed is valid
        if (embedHTML) {
            props.insertEmbed(embedHTML);
        }
        setEmbedModalIsOpen(false);
    };

    const openLinkModal = () => {
        setLinkModalIsOpen(true);
    };

    const openEmbedModal = () => {
        setEmbedModalIsOpen(true);
    };

    const insertLink = (href, title) => {
        if (href && title) {
            props.insertLink(href, title);
        }
        setLinkModalIsOpen(false);
    };

    const alignLeft = () => {
        props.alignText("left")();
    };

    const alignCenter = () => {
        props.alignText("center")();
    };

    const alignRight = () => {
        props.alignText("right")();
    };

    const applyFontSize = (size) => {
        props.applyFontSize(size);
        setCurrentFontSize(size);
    };

    const toggleCodeBlock = () => {
        props.toggleCodeBlock();
    };

    return (
        <div className={`Toolbar ${props.className}`}>
            <div
                className={`overlay ${
                    modalIsOpen || linkModalIsOpen || embedModalIsOpen
                        ? ""
                        : "d-none"
                }`}
            ></div>
            <div className={`toolbar-img-modal ${modalIsOpen ? "" : "d-none"}`}>
                <i
                    className="fa-solid fa-circle-xmark exit-btn"
                    onClick={() => {
                        setModalIsOpen(false);
                        setCaption("");
                        setLinkTitle("");
                        setLinkUrl("");
                        setUrl("");
                        setSource("");
                    }}
                ></i>
                <div className="row">
                    <div className="col-md-5">
                        <p className="lead upload-title">Image Upload</p>
                        <hr></hr>
                        <label className="upload-label" for="url">
                            Image Url
                        </label>
                        <input
                            onChange={(e) => {
                                setUrl(e.target.value);
                            }}
                            className="upload-form"
                            value={imgUrl}
                            name="url"
                            type="text"
                            placeholder="Insert the url for the image"
                        ></input>
                        <label className="upload-label" for="caption">
                            Caption
                        </label>
                        <input
                            onChange={(e) => {
                                setCaption(e.target.value);
                            }}
                            className="upload-form"
                            value={imgCaption}
                            name="caption"
                            type="text"
                            placeholder="Add a caption to the image"
                        ></input>
                        <label className="upload-label" for="source">
                            Source
                        </label>
                        <input
                            onChange={(e) => {
                                setSource(e.target.value);
                            }}
                            className="upload-form"
                            value={imgSource}
                            name="source"
                            type="text"
                            placeholder="Add a caption to the image"
                        ></input>
                        <button
                            onClick={() => {
                                addImage();
                            }}
                            className="btn btn-outline-primary img-submit-btn"
                        >
                            Add Image
                        </button>
                    </div>
                    <div className="col-md-7">
                        <p className="lead upload-title">Preview</p>
                        {isValidUrl(imgUrl) ? (
                            <div className="image-holder">
                                <img
                                    className="image-asset"
                                    alt={imgCaption}
                                    src={imgUrl}
                                ></img>{" "}
                                <p className="image-information">
                                    <b className="image-source">{imgSource}</b>{" "}
                                    <span className="image-caption">
                                        {imgCaption}
                                    </span>
                                </p>
                            </div>
                        ) : (
                            <div className="image-placeholder">
                                <p className="img-preview-text">
                                    Provide a valid image url for a preview
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={`toolbar-img-modal link-modal ${
                    linkModalIsOpen ? "" : "d-none"
                }`}
            >
                <i
                    className="fa-solid fa-circle-xmark exit-btn"
                    onClick={() => {
                        setLinkModalIsOpen(false);
                        setLinkTitle("");
                        setLinkUrl("");
                    }}
                ></i>
                <div>
                    <p className="lead">Add A Link</p>
                    <hr></hr>
                    <label className="upload-label" for="url">
                        Url
                    </label>
                    <input
                        onChange={(e) => {
                            setLinkUrl(e.target.value);
                        }}
                        className="upload-form"
                        value={linkUrl}
                        name="linkUrl"
                        type="text"
                        placeholder="Insert the url for the image"
                    ></input>
                    <label className="upload-label" for="caption">
                        Title
                    </label>
                    <input
                        onChange={(e) => {
                            setLinkTitle(e.target.value);
                        }}
                        className="upload-form"
                        value={linkTitle}
                        name="linkTitle"
                        type="text"
                        placeholder="Add a caption to the image"
                    ></input>
                    <button
                        onClick={() => {
                            insertLink(linkUrl, linkTitle);
                        }}
                        className="btn btn-outline-primary img-submit-btn"
                    >
                        Add Link
                    </button>
                </div>
            </div>
            <div
                className={`toolbar-img-modal ${
                    embedModalIsOpen ? "" : "d-none"
                }`}
            >
                <i
                    className="fa-solid fa-circle-xmark exit-btn"
                    onClick={() => {
                        setEmbedModalIsOpen(false);
                        setEmbedHTML("");
                    }}
                ></i>
                <div>
                    <p className="lead">Add an embed</p>
                    <hr></hr>
                    <label className="lead" for="url">
                        Shortcode
                    </label>
                    <textarea
                        onChange={(e) => {
                            setEmbedHTML(e.target.value);
                        }}
                        className="form-control embed-text-area"
                        value={embedHTML}
                        name="embedHTML"
                        type="text"
                        placeholder="Insert the embed"
                    ></textarea>
                    <div>
                        <br />
                        <br />
                        <button
                            onClick={() => {
                                insertEmbed();
                            }}
                            className="btn btn-outline-primary img-submit-btn"
                        >
                            Add Embed
                        </button>
                    </div>
                </div>
            </div>
            <div className="btn-container">
                <p className="lead-text">Toolbar</p>
                <button
                    className={`top-btn btn ${
                        activeBtns && activeBtns["strong"] ? "active" : ""
                    }`}
                    onClick={bold}
                >
                    <i className="fa-solid fa-bold"></i>
                </button>
                <button
                    className={`btn ${
                        activeBtns && activeBtns["italic"] ? "active" : ""
                    }`}
                    onClick={italic}
                >
                    <i className="fa-solid fa-italic"></i>
                </button>

                <button
                    className={`btn ${
                        activeBtns && activeBtns["code"] ? "active" : ""
                    }`}
                    onClick={openEmbedModal}
                >
                    <i className="fa-solid fa-anchor"></i>
                </button>

                <button className="btn" onClick={openLinkModal}>
                    <i className="fa-solid fa-link"></i>
                </button>

                <button className="bottom-btn btn" onClick={openImageModal}>
                    <i className="fa-regular fa-image"></i>
                </button>

                <button className="btn" onClick={alignLeft}>
                    <i className="fa-solid fa-align-left"></i>
                </button>

                <button className="btn" onClick={alignCenter}>
                    <i className="fa-solid fa-align-center"></i>
                </button>

                <button className="btn" onClick={alignRight}>
                    <i className="fa-solid fa-align-right"></i>
                </button>

                <button className="btn" onClick={toggleCodeBlock}>
                    <i className="fa-solid fa-code"></i>
                </button>
                <div>
                    <label className="font-size-label" for="font-size">
                        Font Size
                    </label>
                    <select
                        value={currentFontSize}
                        className="font-size"
                        name="font-size"
                        onChange={(e) => applyFontSize(e.target.value)}
                    >
                        <option value="16px">16px</option>
                        <option value="20px">20px</option>
                        <option value="24px">24px</option>
                        <option value="26px">26px</option>
                        <option value="28px">28px</option>
                        <option value="30px">30px</option>
                    </select>
                </div>
                {/* Add more buttons as needed */}
            </div>
            <hr></hr>
            <div className="btn-container">
                <p className="lead-text">Topics</p>
                {industries.map((industry) => (
                    <span
                        key={`select.${industry}`}
                        onClick={() => {
                            const newTopics = new Set([...props.topics]);
                            if (props.topics.has(industry)) {
                                newTopics.delete(industry);
                            } else {
                                newTopics.add(industry);
                            }
                            props.setTopics(newTopics);
                        }}
                        className={
                            props.topics.has(industry)
                                ? "industry-selection industry-selected"
                                : "industry-selection"
                        }
                    >
                        {industry}
                    </span>
                ))}
            </div>
            <div id="snapshot-container">
                <hr></hr>
                <div className="btn-container">
                    <p className="lead-text">
                        Snapshots
                        <button
                            id="clear-snapshot-btn"
                            className="btn"
                            onClick={() => props.clearSnapshots()}
                        >
                            Clear
                        </button>
                    </p>
                    {props.snapshots ? (
                        props.snapshots
                            .map((snap, i) => (
                                <div
                                    className="snapshot-restore-btn"
                                    key={`snapshot-${i}`}
                                    onClick={() =>
                                        props.loadContentFromSnapshot(snap)
                                    }
                                >
                                    <div className="snapshot-content-container">
                                        <p className="snapshot-title">
                                            {snap.title || "Untitled"}
                                        </p>
                                        <small className="snapshot-timestamp">
                                            <strong>Last Updated: </strong>
                                            {formatTimestamp(snap.timestamp)}
                                        </small>
                                    </div>
                                    <hr></hr>
                                </div>
                            ))
                            .reverse()
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Toolbar;
