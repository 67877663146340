import "./UserCard.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const UserCard = (props) => {
    const [photo, setPhoto] = useState(props.img);
    return (
        <div className="user-card d-inline-block">
            {props.img ? (
                <Link to={`/user/${props.username}`}>
                    <img
                        className="img-fluid user-card-photo"
                        src={photo}
                        onError={() => {
                            setPhoto(
                                "https://www.svgrepo.com/show/327465/person-circle.svg"
                            );
                        }}
                        alt="author"
                    ></img>
                </Link>
            ) : (
                <></>
            )}
            <div className="d-inline-block user-card-content">
                <Link className="user-card-a" to={`/user/${props.username}`}>
                    <strong className="user-card-link">{props.username}</strong>
                </Link>
                {/* <span className="badge-trailblazer-bg-less">Trailblazer</span> */}
                {props.company ? (
                    <span className="rep-title">{props.company}</span>
                ) : (
                    <></>
                )}
                <br></br>
                <span className="user-card-info">
                    {props.headline}
                    <br></br>
                    {/* 10h */}
                </span>
            </div>
        </div>
    );
};

export default UserCard;
