import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import ProblemList from "./Components/ListProblem/ProblemList";
import ListTrending from "./Components/ListProblem/ListTrending/ListTrending";
import Post from "./Components/Post/Post";
import User from "./Components/User/User";
import NewPost from "./Components/NewPost/NewPost";
import Login from "./Components/Login/Login";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Register from "./Components/Register/Register";

function App() {
    const [user, setUser] = useState(null);
    const [topic, setTopic] = useState("");

    // check if they have a valid jwt token already, if they do log them in
    useEffect(() => {
        fetch(
            (process.env.REACT_APP_BACKEND_URL || "http://localhost:2001") +
                "/verify",
            {
                method: "GET",
                credentials: "include",
            }
        )
            .then((response) => {
                console.log(response);
                if (response.status === 404) {
                    return;
                }
                return response.json();
            })
            .then((data) => {
                console.log("DATA", data);
                setUser(data);
            });
    }, []);

    return (
        <BrowserRouter>
            <div className="App">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <div>
                                <Navbar
                                    topic={topic}
                                    setTopic={setTopic}
                                    user={user}
                                    setUser={setUser}
                                />
                                {user ? (
                                    <Link
                                        to="/post/new"
                                        className="create-post-btn"
                                    >
                                        + Post a Problem
                                    </Link>
                                ) : (
                                    <></>
                                )}
                                <ListTrending />
                                <br></br>
                                <ProblemList user={user} />{" "}
                            </div>
                        }
                    />
                    <Route
                        path="/post/new"
                        element={<NewPost user={user} setUser={setUser} />}
                    />
                    <Route
                        path="/post/:postId"
                        element={
                            <div>
                                <Navbar
                                    topic={topic}
                                    setTopic={setTopic}
                                    user={user}
                                    setUser={setUser}
                                />
                                <Post
                                    user={user}
                                    setUser={setUser}
                                    setTopic={setTopic}
                                />
                            </div>
                        }
                    />
                    <Route
                        path="/user/:username"
                        element={
                            <div>
                                <Navbar
                                    topic={topic}
                                    setTopic={setTopic}
                                    user={user}
                                    setUser={setUser}
                                />
                                <User
                                    user={user}
                                    setUser={setUser}
                                    setTopic={setTopic}
                                />
                            </div>
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <div>
                                <Navbar
                                    topic={topic}
                                    setTopic={setTopic}
                                    user={user}
                                    setUser={setUser}
                                />
                                <Login setUser={setUser} />
                            </div>
                        }
                    />
                    <Route
                        path="/register"
                        element={
                            <div>
                                <Navbar
                                    topic={topic}
                                    setTopic={setTopic}
                                    user={user}
                                    setUser={setUser}
                                />
                                <Register setUser={setUser} />
                            </div>
                        }
                    />
                    <Route
                        path="/:topic"
                        element={
                            <div>
                                <Navbar
                                    topic={topic}
                                    setTopic={setTopic}
                                    user={user}
                                    setUser={setUser}
                                />
                                {user ? (
                                    <Link
                                        to="/post/new"
                                        className="create-post-btn"
                                    >
                                        + Post a Problem
                                    </Link>
                                ) : (
                                    <></>
                                )}
                                <ProblemList user={user} />
                            </div>
                        }
                    />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
