import "./Problem.css";
import { Link } from "react-router-dom";
import UserCard from "../../UserCard/UserCard";

const getThumbnail = (images) => {
    for (const image in images) {
        if (image.type === "thumbnail") return image;
    }
    return images[0];
};

const getDate = (date) => {
    if (!date) {
        return "";
    }

    const shortMonthName = new Intl.DateTimeFormat("en-US", {
        month: "short",
    }).format;
    date = new Date(date);
    const shortName = shortMonthName(date); // "Jul"

    return `${shortName} ${date.getDay() + 1}, ${date.getFullYear()}`;
};

function Problem(props) {
    const handleLike = (value) => {
        console.log(props.post);
        if (!props.user) return;
        fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/likes/like`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                // mode: "cors",
                body: JSON.stringify({
                    contentId: props.post._id,
                    type: "post",
                    value,
                    username: props.user ? props.user.username : "",
                }),
            }
        ).then(() => props.fetchPosts());
    };
    return (
        <div id="Problem" className="p-3">
            <div id="problem-content" className="row">
                <div className="problem-likes">
                    <div id="button-box">
                        <div>
                            <i
                                className={`fa-solid fa-chevron-up${
                                    props.post.likeByUser &&
                                    props.post.likeByUser.length > 0 &&
                                    props.post.likeByUser[0].value === 1
                                        ? " liked"
                                        : ""
                                }`}
                                onClick={() => handleLike(1)}
                            ></i>
                        </div>
                        <span className="likes">
                            {props.post.numberOfLikes || 0}
                        </span>
                        <div>
                            <i
                                className={`fa-solid fa-chevron-down${
                                    props.post.likeByUser &&
                                    props.post.likeByUser.length > 0 &&
                                    props.post.likeByUser[0].value === -1
                                        ? " liked"
                                        : ""
                                }`}
                                onClick={() => handleLike(-1)}
                            ></i>
                        </div>
                    </div>
                </div>
                <div className="card-holder">
                    <div>
                        <UserCard
                            img={
                                props.post?.author?.photo ||
                                "https://www.svgrepo.com/show/327465/person-circle.svg"
                            }
                            headline={
                                props.post?.author?.headline ||
                                "Data Scientist @ Amazon"
                            }
                            username={props.post?.author?.username}
                            company={props.post?.author?.company}
                        />
                        <div className="badge-box">
                            <span className="post-solution-badge">
                                {props.post.solutions.length || 0} Solutions
                            </span>
                            {props.post.topics && props.post.topics.length ? (
                                props.post.topics
                                    .filter((topic) => topic != null)
                                    .map((topic) => (
                                        <Link to={`/${topic}`}>
                                            <span className="post-industry-badge">
                                                {topic}
                                            </span>
                                        </Link>
                                    ))
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <p className="lead">
                        <Link to={`/post/${props.post._id}`} className="title">
                            {props.post.title && props.post.title.length > 60
                                ? props.post.title.substring(0, 60) + "..."
                                : props.post.title}
                        </Link>{" "}
                        {props.post.updateTime || props.post.createTime
                            ? (
                                  <span className="problem-ts">
                                      Updated {getDate(props.post.updateTime)}
                                  </span>
                              ) || (
                                  <span className="problem-ts">
                                      Created {getDate(props.post.createTime)}
                                  </span>
                              )
                            : ""}
                    </p>
                    <hr></hr>
                    {props.post &&
                    props.post.images &&
                    props.post.images.length ? (
                        <img
                            src={
                                props.post &&
                                props.post.images &&
                                props.post.images.length
                                    ? getThumbnail(props.post.images).url
                                    : ""
                            }
                            alt="thumbnail"
                        ></img>
                    ) : (
                        <></>
                    )}
                    <div className="post-content">
                        <div className="post-overlay"></div>
                        <div className="problem-content-container"
                            dangerouslySetInnerHTML={{
                                __html: props.post.content,
                            }}
                        ></div>
                    </div>
                    <div id="problem-footer"></div>
                </div>
            </div>
        </div>
    );
}

export default Problem;
