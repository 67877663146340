import React, { useState, useEffect } from "react";
import "./Editor.css";
import { ProseMirror } from "@nytimes/react-prosemirror";
import { EditorState, NodeSelection, TextSelection } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { Plugin } from "prosemirror-state";
import { Schema, DOMSerializer } from "prosemirror-model";
import { keymap } from "prosemirror-keymap";
import { undo, redo, history } from "prosemirror-history";
import { baseKeymap, setBlockType } from "prosemirror-commands";
import Toolbar from "./Components/Toolbar";

// Constants
const MAX_SNAPSHOTS = 10;
const SNAPSHOT_KEY = "editorSnapshots";

const preserveStoredMarksPlugin = new Plugin({
    appendTransaction: (transactions, oldState, newState) => {
        // Check if the document is empty
        if (newState.doc.content.size === 0) {
            // Preserve stored marks
            const storedMarks = oldState.storedMarks || [];
            return newState.tr.setStoredMarks(storedMarks);
        }
    },
});

function placeholder(text) {
    return new Plugin({
        props: {
            decorations(state) {
                let doc = state.doc;
                if (
                    doc.childCount === 1 &&
                    doc.firstChild.isTextblock &&
                    doc.firstChild.content.size === 0
                ) {
                    const placeholder = document.createElement("span");
                    placeholder.textContent = text;
                    placeholder.className = "placeholder-text";
                    return DecorationSet.create(doc, [
                        Decoration.widget(1, placeholder),
                    ]);
                }
                return null;
            },
        },
    });
}

const fontSizeMark = {
    attrs: {
        size: { default: "24px" },
    },
    parseDOM: [
        {
            style: "font-size",
            getAttrs: (value) => ({ size: value }),
        },
    ],
    toDOM: (mark) => ["span", { style: `font-size: ${mark.attrs.size}` }, 0],
};

const Editor = ({
    setContent,
    setTitle,
    setImages,
    setTopics,
    topics,
    title,
    user,
}) => {
    const [mount, setMount] = useState(null);
    const [snapshots, setSnapshots] = useState(
        JSON.parse(localStorage.getItem(SNAPSHOT_KEY)) || []
    );
    const [editorState, setEditorState] = useState(() =>
        EditorState.create({
            schema: new Schema({
                nodes: {
                    doc: { content: "block+" },
                    paragraph: {
                        content: "inline*",
                        group: "block",
                        toDOM() {
                            return ["p", 0];
                        },
                        attrs: { textAlign: { default: "left" } }, // Add a textAlign attribute
                        toDOM(node) {
                            return [
                                "p",
                                {
                                    style: `text-align: ${node.attrs.textAlign}`,
                                },
                                0,
                            ];
                        },
                        parseDOM: [
                            {
                                tag: "p",
                                getAttrs(dom) {
                                    return {
                                        textAlign:
                                            dom.style.textAlign || "left",
                                    };
                                },
                            },
                        ],
                    },
                    code_block: {
                        content: "text*",
                        group: "block",
                        marks: "", // Disable marks inside code blocks
                        code: true, // Special flag for code blocks
                        defining: true,
                        parseDOM: [{ tag: "pre", preserveWhitespace: "full" }],
                        toDOM() {
                            return ["pre", ["code", 0]];
                        },
                    },
                    text: { group: "inline" },
                    image: {
                        inline: true,
                        attrs: {
                            src: {},
                            alt: { default: null },
                            title: { default: null },
                        },
                        group: "inline",
                        draggable: true,
                        parseDOM: [
                            {
                                tag: "img[src]",
                                getAttrs(dom) {
                                    return {
                                        src: dom.getAttribute("src"),
                                        alt: dom.getAttribute("alt"),
                                        title: dom.getAttribute("title"),
                                    };
                                },
                            },
                        ],
                        toDOM(node) {
                            return ["img", node.attrs];
                        },
                    },
                    imageCard: {
                        group: "block",
                        attrs: {
                            src: { default: null },
                            caption: { default: "" },
                            source: { default: "" },
                            alignment: { default: "left" }, // Consistently use alignment
                        },
                        draggable: true,
                        parseDOM: [
                            {
                                tag: "div.image-holder",
                                getAttrs(dom) {
                                    const img =
                                        dom.querySelector("img.image-asset");
                                    const caption =
                                        dom.querySelector(".image-caption");
                                    const source =
                                        dom.querySelector(".image-source");
                                    const alignment =
                                        dom.style.textAlign || "left";
                                    return {
                                        src: img?.getAttribute("src"),
                                        caption: caption?.innerText,
                                        source: source?.innerText,
                                        alignment,
                                    };
                                },
                            },
                        ],
                        toDOM(node) {
                            const { src, caption, source, alignment } =
                                node.attrs;
                            return [
                                "div",
                                {
                                    class: "image-holder",
                                    style: `text-align: ${alignment};`,
                                },
                                ["img", { class: "image-asset", src }],
                                [
                                    "p",
                                    { class: "image-information" },
                                    ["b", { class: "image-source" }, source],
                                    " ",
                                    [
                                        "span",
                                        { class: "image-caption" },
                                        caption,
                                    ],
                                ],
                            ];
                        },
                    },
                    // Embed Node
                    embed: {
                        group: "block",
                        inline: false,
                        attrs: {
                            embedHTML: { default: "" },
                            textAlign: { default: "left" }, // Add textAlign attribute
                        },
                        parseDOM: [
                            {
                                tag: "div.embed-wrapper",
                                getAttrs(dom) {
                                    return {
                                        embedHTML: dom.innerHTML,
                                        textAlign:
                                            dom.style.textAlign || "left", // Parse textAlign
                                    };
                                },
                            },
                        ],
                        toDOM(node) {
                            const wrapper = document.createElement("div");
                            wrapper.className = "embed-wrapper";
                            wrapper.style.textAlign = node.attrs.textAlign; // Apply textAlign style

                            const content = document.createElement("div");
                            content.innerHTML = node.attrs.embedHTML;
                            wrapper.appendChild(content);

                            return wrapper;
                        },
                    },
                },
                marks: {
                    fontSize: fontSizeMark,
                    link: {
                        attrs: {
                            href: {},
                            title: { default: null },
                        },
                        inclusive: false, // Makes sure the link is applied to only the selected text
                        parseDOM: [
                            {
                                tag: "a[href]",
                                getAttrs(dom) {
                                    return {
                                        href: dom.getAttribute("href"),
                                        title: dom.getAttribute("title"),
                                    };
                                },
                            },
                        ],
                        toDOM(node) {
                            return [
                                "a",
                                {
                                    href: node.attrs.href,
                                    title: node.attrs.title,
                                },
                                0,
                            ];
                        },
                    },
                    strong: {
                        toDOM() {
                            return ["strong", 0];
                        },
                        parseDOM: [{ tag: "strong" }],
                    },
                    // Bold mark
                    bold: {
                        toDOM() {
                            return ["strong", 0];
                        }, // Renders as <strong>
                        parseDOM: [
                            { tag: "strong" }, // Parses <strong>
                            { tag: "b", getAttrs: () => null }, // Parses <b>
                            {
                                style: "font-weight",
                                getAttrs: (value) => value === "bold" && null,
                            },
                        ],
                    },
                    // Italic mark
                    italic: {
                        toDOM() {
                            return ["em", 0];
                        }, // Renders as <em>
                        parseDOM: [
                            { tag: "em" }, // Parses <em>
                            { tag: "i", getAttrs: () => null }, // Parses <i>
                            {
                                style: "font-style",
                                getAttrs: (value) => value === "italic" && null,
                            },
                        ],
                    },
                    // Code mark
                    code: {
                        toDOM() {
                            return ["code", 0];
                        }, // Renders as <code>
                        parseDOM: [{ tag: "code" }], // Parses <code>
                    },
                },
            }),
            plugins: [
                history(),
                placeholder("start typing here..."),
                preserveStoredMarksPlugin,
                keymap({
                    ...baseKeymap,
                    "Mod-z": undo,
                    "Mod-y": redo,
                }),
            ],
        })
    );

    const editorStateToHTML = (editorState) => {
        const { doc, schema } = editorState;

        // Serialize the document to a DOM structure
        const fragment = DOMSerializer.fromSchema(schema).serializeFragment(
            doc.content
        );

        // Create a temporary container to hold the serialized DOM
        const temporaryDiv = document.createElement("div");
        temporaryDiv.appendChild(fragment);

        // Get the HTML string from the container
        return temporaryDiv.innerHTML;
    };

    // Deletes all saved snapshots
    const clearSnapshots = () => {
        setSnapshots([]);
        localStorage.setItem(SNAPSHOT_KEY, JSON.stringify([]));
    };

    // Function to store snapshots
    const storeSnapshot = (snapshot) => {
        const content = snapshot.content;

        if (!content || editorState.doc.textContent.trim() === "") {
            console.log(
                "Empty or whitespace-only content. Snapshot not stored."
            );
            return; // Skip storing if the content is empty or just whitespace
        }

        // Use the current state of `snapshots`
        setSnapshots((prevSnapshots) => {
            // Remove duplicates and ensure unique snapshots
            const isDuplicate = prevSnapshots.some(
                (existingSnapshot) =>
                    JSON.stringify(existingSnapshot.content) ===
                    JSON.stringify(snapshot.content)
            );

            if (isDuplicate) {
                console.log("Duplicate content. Snapshot not stored.");
                return prevSnapshots; // No update if duplicate
            }

            // Add the new snapshot and maintain the size limit
            const updatedSnapshots = [...prevSnapshots, snapshot];
            if (updatedSnapshots.length > MAX_SNAPSHOTS) {
                updatedSnapshots.shift(); // Remove the oldest snapshot
            }

            // Update `localStorage`
            localStorage.setItem(
                SNAPSHOT_KEY,
                JSON.stringify(updatedSnapshots)
            );

            return updatedSnapshots; // Return the updated state
        });
    };
    // Function to unmarshal and set content from stored snapshot
    const loadContentFromSnapshot = (snapshot) => {
        const newDoc = editorState.schema.nodeFromJSON(snapshot.content);

        const tr = editorState.tr.replaceWith(
            0,
            editorState.doc.content.size,
            newDoc
        );

        setEditorState((state) => state.apply(tr));
        setContent(editorStateToHTML(editorState));
        setTitle(snapshot.title);
    };

    const insertEmbed = (embedHTML) => {
        const { schema, selection } = editorState;
        const { from } = selection;

        // Create the embed node with the given HTML
        const embedNode = schema.nodes.embed.create({ embedHTML });

        // Create a new transaction to insert the embed node
        const tr = editorState.tr.insert(from, embedNode);

        // Dispatch the transaction to update the editor state
        dispatchTransaction(tr);
    };

    const applyFontSize = (fontSize) => {
        const { schema, selection } = editorState;
        const { from, to, empty } = selection;
        const markType = schema.marks.fontSize;

        if (!markType) return false; // Ensure the mark exists

        if (empty) {
            // Store the mark for new text
            dispatchTransaction(
                editorState.tr.setStoredMarks([
                    markType.create({ size: fontSize }),
                ])
            );
        } else {
            // Apply the mark to the selected text
            dispatchTransaction(
                editorState.tr.addMark(
                    from,
                    to,
                    markType.create({ size: fontSize })
                )
            );
        }
    };

    // toggles a code block
    const toggleCodeBlock = () => {
        const { schema, selection } = editorState;
        const { from, to } = selection;

        // Check if current selection is already a code block
        const isCodeBlock = selection.$from.parent.hasMarkup(
            schema.nodes.code_block
        );

        // Toggle between paragraph and code block
        const blockType = isCodeBlock
            ? schema.nodes.paragraph
            : schema.nodes.code_block;
        dispatchTransaction(editorState.tr.setBlockType(from, to, blockType));
    };

    // alligns text
    const alignText = (alignment) => () => {
        const { schema, selection } = editorState;
        const { from, to } = selection;

        const tr = editorState.tr; // Use a single transaction

        editorState.doc.nodesBetween(from, to, (node, pos) => {
            if (
                node.type === schema.nodes.paragraph ||
                node.type === schema.nodes.embed ||
                node.type === schema.nodes.imageCard
            ) {
                // Update textAlign for paragraph and embed
                const newAttrs = {
                    ...node.attrs,
                    textAlign: alignment,
                };

                // For `imageCard`, ensure `alignment` is used as an attribute
                if (node.type === schema.nodes.imageCard) {
                    newAttrs.alignment = alignment; // Proper alignment attribute
                }

                tr.setNodeMarkup(pos, node.type, newAttrs);
            }
        });

        dispatchTransaction(tr); // Dispatch the final transaction
    };

    // Function to insert an image
    const insertImage = (src, caption, source) => {
        const { schema, selection } = editorState;
        const { from } = selection;

        // Create the image card node with the provided attributes
        const imageCardNode = schema.nodes.imageCard.create({
            src,
            caption,
            source,
        });

        // Create a new transaction to insert the image card
        const tr = editorState.tr.insert(from, imageCardNode);

        // Dispatch the transaction to update the editor state
        dispatchTransaction(tr);
    };

    const insertLink = (href, title = "") => {
        const { schema, selection } = editorState;
        const linkMark = schema.marks.link;

        // Check if a link mark is already applied
        if (selection.empty) {
            const { from } = selection;
            // If there's no selection, create a link in place
            const linkText = schema.text(title, [
                linkMark.create({ href, title }),
            ]);

            // Create a transaction to insert the link text at the cursor
            const tr = editorState.tr.insert(from, linkText);

            // Set the selection after the inserted link
            const newSelection = TextSelection.create(
                tr.doc,
                from + linkText.nodeSize
            );
            tr.setSelection(newSelection);

            dispatchTransaction(tr);

            return;
        }

        // Create a transaction to add the link mark to the selected text
        const tr = editorState.tr.addMark(
            selection.from,
            selection.to,
            linkMark.create({ href, title })
        );

        // Dispatch the transaction to update the editor state
        dispatchTransaction(tr);
    };

    // Dispatch transaction from the editor
    const dispatchTransaction = (tr) => {
        if (!tr.doc || !tr.doc.content) {
            console.error("Invalid transaction detected");
            return;
        }

        setEditorState((state) => state.apply(tr));
        setContent(editorStateToHTML(editorState));
    };

    const handleEditorChange = (newState) => {
        setEditorState(newState);
        setContent(editorStateToHTML(editorState)); // Update the content state
        storeSnapshot({
            title,
            timestamp: new Date().getTime(),
            content: newState.doc.toJSON(),
        });
    };

    // Set an interval to periodically store persistent copy
    useEffect(() => {
        const intervalId = setInterval(() => {
            setSnapshots((prevSnapshots) => {
                const currentSnapshot = {
                    title,
                    timestamp: new Date().getTime(),
                    content: editorState.doc.toJSON(),
                };

                // Check for duplicates before adding
                const isDuplicate = prevSnapshots.some(
                    (existingSnapshot) =>
                        JSON.stringify(existingSnapshot.content) ===
                        JSON.stringify(currentSnapshot.content)
                );

                if (isDuplicate) return prevSnapshots;

                const updatedSnapshots = [...prevSnapshots, currentSnapshot];
                if (updatedSnapshots.length > MAX_SNAPSHOTS) {
                    updatedSnapshots.shift();
                }

                localStorage.setItem(
                    SNAPSHOT_KEY,
                    JSON.stringify(updatedSnapshots)
                );
                return updatedSnapshots;
            });
        }, 5000); // every 5 seconds

        editorState.doc.forEach((node) => {
            if (node.type.name === "imageCard") {
                const newImages = [];
                // Push relevant data (e.g., position, attributes) into the array
                newImages.push({
                    ...node.attrs,
                    url: node.attrs.src,
                });
                setImages(newImages);
            }
        });

        setContent(editorStateToHTML(editorState));

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [editorState, title]);

    return (
        <div id="Editor" className="row" style={styles.editorContainer}>
            <ProseMirror
                state={editorState}
                onChange={handleEditorChange}
                options={{
                    placeholder: "Start typing here...",
                }}
                style={styles.editor}
                mount={mount}
                dispatchTransaction={dispatchTransaction}
            >
                <Toolbar
                    className="col-12 col-md-2"
                    insertImage={insertImage}
                    insertLink={insertLink}
                    insertEmbed={insertEmbed}
                    loadContentFromSnapshot={loadContentFromSnapshot}
                    snapshots={snapshots}
                    setTopics={setTopics}
                    topics={topics}
                    clearSnapshots={clearSnapshots}
                    alignText={alignText}
                    applyFontSize={applyFontSize}
                    toggleCodeBlock={toggleCodeBlock}
                />
            </ProseMirror>
            <div className="col-md-10 mt-3">
                <input
                    id="title"
                    className="mb-2 new-post-title"
                    placeholder="Title.."
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                    required="true"
                />
                <span className="editor-label">
                    written by {user && user.label ? user.label : "anonymous"}
                </span>
                <div className="editor-container" ref={setMount}>
                    <p>Start typing here...</p>
                </div>
            </div>
        </div>
    );
};

const styles = {
    editorContainer: {
        // paddingLeft: "30px",
        paddingTop: "30px",
        width: "100vw",
        marginBottom: "0px",
        fontSize: "1.5em",
        height: "100vw",
        marginTop: "35px",
    },
    editor: {
        fontSize: "1.5em",
        lineHeight: "10px",
        minHeight: "500px",
        marginBottom: "0px",
        marginTop: "0px",
        paddingBottom: "0px",
    },
};

export default Editor;
