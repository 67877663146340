import "./Solution.css";
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

const getDate = (date) => {
    if (!date) {
        return "";
    }

    const shortMonthName = new Intl.DateTimeFormat("en-US", {
        month: "short",
    }).format;
    date = new Date(date);
    const shortName = shortMonthName(date); // "Jul"

    return `${shortName} ${date.getDay() + 1}, ${date.getFullYear()}`;
};

const useTruncatedElement = ({ ref }) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const [isReadingMore, setIsReadingMore] = useState(false);

    useLayoutEffect(() => {
        const { offsetHeight, scrollHeight } = ref.current || {};

        if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
            setIsTruncated(true);
        } else {
            setIsTruncated(false);
        }
    }, [ref]);

    return {
        isTruncated,
        isReadingMore,
        setIsReadingMore,
    };
};

const Solution = (props) => {
    const ref = React.useRef(null);
    const { isTruncated, isReadingMore, setIsReadingMore } =
        useTruncatedElement({
            ref,
        });
    const [pageNumber, setPageNumber] = useState(1);
    const [pages, setPages] = useState([]);

    const generatePages = (solutions, pageSize) => {
        const newPages = [];
        let currentPage = [];

        for (const solution of solutions) {
            currentPage.push(solution);

            if (currentPage.length >= pageSize) {
                newPages.push(currentPage);
                currentPage = [];
            }
        }

        if (currentPage.length) {
            newPages.push(currentPage);
        }
        setPages(newPages);
    };

    const generateTabs = () => {
        const tabs = [];

        for (let i = 0; i < pages.length; i++) {
            tabs.push(
                <span
                    className={`page-number ${
                        pageNumber === i + 1 ? "active" : ""
                    }`}
                    onClick={() => {
                        setPageNumber(i + 1);
                    }}
                >
                    {i + 1}
                </span>
            );
        }

        return tabs;
    };

    useEffect(() => {
        // Get Solutions
        if (props.postId && props.solutions) {
            generatePages(props.solutions, 3);
        }
    }, [props.solutions]);

    return (
        <div className="Solutions">
            {props.solutions && props.solutions.length ? (
                <>
                    <h1 className="solutions-header-title">Solutions</h1>
                    <hr></hr>
                </>
            ) : (
                <></>
            )}
            <div className="solutions-wrapper">
                {pages.length ? (
                    pages[pageNumber - 1].map((solution, idx) => (
                        <div className="Solution">
                            <div className="solution-number">{`${
                                idx + 1
                            }.  `}</div>
                            <div className="solution-content-block">
                                <Link
                                    className="solution-title"
                                    to={`/post/${solution._id}`}
                                >
                                    {solution.title}
                                </Link>
                                <br></br>
                                <Link
                                    to={`/author/${solution.author.username}`}
                                    className="solution-author"
                                >{`${solution.author.username}`}</Link>{" "}
                                {"  "}
                                <small className="post-time">
                                    {solution.updateTime
                                        ? "Updated " +
                                          getDate(solution.updateTime)
                                        : "Created " +
                                          getDate(solution.createTime)}
                                </small>
                            </div>
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
            <hr></hr>
            {pages.length ? generateTabs() : <></>}
        </div>
    );
};

export default Solution;
