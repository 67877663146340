import "./Register.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const topics = [
    "sports",
    "tech",
    "art",
    "cars",
    "finance",
    "business",
    "corporate",
    "videogames",
];

function Register(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [display, setDisplay] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [status, setStatus] = useState("");
    const [statusColor, setStatusColor] = useState("danger");
    const [interests, setInterests] = useState(new Set());

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/auth/register`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                // mode: "cors",
                body: JSON.stringify({
                    username,
                    password,
                    label: display,
                    preferences: [...interests],
                    firstName: firstName,
                    lastName: lastName,
                    email,
                }),
            }
        )
            .then((response) => {
                if (response.status === 201) {
                    setStatusColor("success");
                }
                return response.json();
            })
            .then((data) => {
                if (!data._id) {
                    throw new Error(
                        `failed to create account: ${data.message}`
                    );
                }
                props.setUser({
                    _id: data._id,
                    username: data.username,
                    label: data.label,
                    company: data.company,
                });
                setStatusColor("success");
                setStatus(data.message);
            })
            .catch((e) => {
                setStatusColor("danger");
                setStatus(e.message);
            });
        setTimeout(() => {
            let redirect = statusColor === "success";
            setStatusColor("danger");
            setStatus("");
            if (redirect) {
                return navigate("/");
            }
        }, 10000);
    };

    const toggleTopic = (topic) => {
        const copyOfInterests = new Set([...interests]);
        if (interests.has(topic)) {
            copyOfInterests.delete(topic);
        } else {
            copyOfInterests.add(topic);
        }

        setInterests(copyOfInterests);
    };

    const usernameIsValid = () =>
        username && username.length >= 6 && !username.match(/\s/);
    const passwordIsValid = () =>
        password && password.length >= 6 && !password.match(/\s/);
    const emailIsValid = () =>
        email.match(
            /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/
        );
    const displayIsValid = () => display.length > 0;
    return (
        <div className="Register p-3">
            <p className="display-5">Make your ideas a reality</p>
            <div className="register-box">
                <h2>General Info</h2>
                <form className="form-signin" onSubmit={(e) => handleSubmit(e)}>
                    Username
                    <input
                        type="username"
                        className={
                            usernameIsValid()
                                ? "form-control mb-2"
                                : "form-control mb-2 red-border"
                        }
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        required="true"
                    />
                    {!usernameIsValid() ? (
                        <small className="error-message">
                            Please enter a username that's atleast 6 characters
                            long and does not include spaces.
                        </small>
                    ) : (
                        <></>
                    )}
                    <br />
                    Password (6+ characters)
                    <input
                        type="password"
                        className={
                            passwordIsValid()
                                ? "form-control mb-2"
                                : "form-control mb-2 red-border"
                        }
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required="true"
                    />
                    {!passwordIsValid() ? (
                        <small className="error-message">
                            Please enter a password that's atleast 6 characters
                            long and does not include spaces.
                        </small>
                    ) : (
                        <></>
                    )}
                    <br />
                    Confirm your password
                    <input
                        type="password"
                        className={
                            confirmPassword === password
                                ? "form-control mb-2"
                                : "form-control mb-2 red-border"
                        }
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        required="true"
                    />
                    {confirmPassword !== password ? (
                        <small className="error-message">
                            Password doesn't match.
                        </small>
                    ) : (
                        <></>
                    )}
                    <br></br>
                    Email Address
                    <input
                        type="email"
                        className={
                            emailIsValid()
                                ? "form-control mb-2"
                                : "form-control mb-2 red-border"
                        }
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required="true"
                    />
                    {!emailIsValid() ? (
                        <small className="error-message">
                            Please enter a email address that's valid.
                        </small>
                    ) : (
                        <></>
                    )}
                    <br />
                    Display Name
                    <input
                        type="text"
                        className={
                            displayIsValid()
                                ? "form-control mb-2"
                                : "form-control mb-2 red-border"
                        }
                        onChange={(e) => setDisplay(e.target.value)}
                        value={display}
                        required="true"
                    />
                    {!displayIsValid() ? (
                        <small className="error-message">
                            Please enter a display name.
                        </small>
                    ) : (
                        <></>
                    )}
                    <br />
                    First Name
                    <input
                        type="text"
                        className="form-control mb-2"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        required="true"
                    />
                    Last Name
                    <input
                        type="text"
                        className="form-control mb-2"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        required="true"
                    />
                    <h2>Interests</h2>
                    <p className="tl text-muted">
                        Help us cater content towards your interests!
                    </p>
                    <div className="topics-container">
                        {topics.map((topic) => (
                            <span
                                className={
                                    interests.has(topic)
                                        ? "topic-selected"
                                        : "topic-selection"
                                }
                                key={topic}
                                onClick={() => toggleTopic(topic)}
                            >
                                {topic}
                            </span>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <p className="privacy-policy text-muted">
                                By clicking Agree & Join, you agree to the
                                SparkNexus User Agreement, Privacy Policy, and
                                Cookie Policy
                            </p>
                            <button
                                onClick={handleSubmit}
                                className="btn btn-lg btn-primary btn-block w-100 mt-3 submit-button"
                            >
                                Agree & Join
                            </button>
                        </div>
                    </div>
                    {status ? (
                        <div className={`alert alert-${statusColor} mt-3`}>
                            {status}
                        </div>
                    ) : (
                        <></>
                    )}
                </form>
            </div>
        </div>
    );
}

export default Register;
