// import "./ProblemList.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ListTrending.css";

function ListTrending() {
    const [trending, setTrending] = useState([]);

    useEffect(() => {
        // get trending posts
        fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/posts/listtest?limit=6`,
            {
                method: "POST",
                credentials: "include",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setTrending(data.posts);
            });
    }, []);

    const getDate = (date) => {
        if (!date) {
            return "";
        }

        const shortMonthName = new Intl.DateTimeFormat("en-US", {
            month: "short",
        }).format;
        date = new Date(date);
        const shortName = shortMonthName(date); // "Jul"

        return `${shortName} ${date.getDay() + 1}, ${date.getFullYear()}`;
    };

    const createColumns = () => {
        if (trending.length < 6) return [];
        const popularPosts = [];

        for (let i = 0; i < 3; i++) {
            const elemsInColumn = [];
            for (let j = 0; j < 2; j++) {
                const currentIdx = 2 * i + j;
                elemsInColumn.push(
                    <div className="col-md-4 col-6">
                        <div className="row">
                            <div className="d-inline-block col-3">
                                <span className="rating-text">
                                    0{currentIdx + 1}
                                </span>
                            </div>
                            <div className="user-headline d-inline-block col-9">
                                <div>
                                    <img
                                        className="user-trending-photo"
                                        src={
                                            trending[currentIdx].author
                                                ?.photo ||
                                            "https://www.svgrepo.com/show/327465/person-circle.svg"
                                        }
                                        onError={() => {
                                            const copyOfTrending = {
                                                ...trending,
                                            };
                                            copyOfTrending[currentIdx].author =
                                                {
                                                    ...copyOfTrending[currentIdx].author,
                                                    photo: "https://www.svgrepo.com/show/327465/person-circle.svg",
                                                };
                                            setTrending(copyOfTrending);
                                        }}
                                        alt="author"
                                    ></img>
                                    <span className="trending-username">
                                        {trending[currentIdx].author?.username}
                                    </span>
                                </div>
                                <Link
                                    to={"/post/" + trending[currentIdx]._id}
                                    className="rating-title"
                                >
                                    {trending[currentIdx].title.length > 60
                                        ? trending[currentIdx].title.substring(
                                              0,
                                              60
                                          ) + "..."
                                        : trending[currentIdx].title}
                                </Link>
                                <p className="popular-post-date">
                                    {trending[currentIdx].updateTime ||
                                    trending[currentIdx].createTime
                                        ? getDate(
                                              trending[currentIdx].updateTime
                                          ) ||
                                          getDate(
                                              trending[currentIdx].createTime
                                          )
                                        : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                );
            }
            popularPosts.push(<>{elemsInColumn}</>);
        }

        return popularPosts;
    };
    return (
        <div id="ListTrending" className="p-3">
            <h1 className="display-5">Trending</h1>
            <div className="popular-posts">
                <div className="row">{createColumns()}</div>
            </div>
        </div>
    );
}

export default ListTrending;
