import "./ProblemList.css";
import Problem from "./Problem/Problem";
import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function ProblemList(props) {
    const [posts, setPosts] = useState([]);
    const [pageToken, setPageToken] = useState("");
    const [listState, setListState] = useState(0);
    const [showSpinner, toggleSpinner] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const postStateRef = useRef(posts);
    const tokenStateRef = useRef(pageToken);
    const location = useLocation();

    const handleScroll = async () => {
        const bottom =
            Math.ceil(window.innerHeight + window.scrollY) >=
            document.documentElement.scrollHeight;

        if (!tokenStateRef.current) {
            toggleSpinner(false);
            return;
        }
        if (!bottom) return;

        toggleSpinner(true);
        let res;

        if (location.pathname === "/") {
            const sort = searchParams.get("sort");

            res = await fetch(
                `${
                    process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                }/posts/listtest?limit=50${
                    "&nextPageToken=" + tokenStateRef.current
                }${sort ? "&sort=" + sort : ""}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    // mode: "cors",
                    body: JSON.stringify({
                        includeIndustries: [],
                        excludeIndustries: [],
                        excludePosts: [],
                        user: props.user ? props.user.username : "",
                    }),
                }
            );
        } else {
            const topic = location.pathname.replaceAll("/", "");
            const sort = searchParams.get("sort");
            res = await fetch(
                `${
                    process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                }/topics/${topic}/posts?limit=50${
                    tokenStateRef.current
                        ? "&nextPageToken=" + tokenStateRef.current
                        : ""
                }${sort ? "&sort=" + sort : ""}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    // mode: "cors",
                }
            );
        }

        const json = await res.json();
        if (!json) return;

        const { nextPageToken, posts: newPosts } = json;

        if (newPosts) {
            const set = new Set();
            const filteredPosts = [...postStateRef.current, ...newPosts].filter(
                (post) => {
                    if (set.has(post._id)) return false;
                    set.add(post._id);
                    return true;
                }
            );

            setPageToken(nextPageToken);
            setPosts(filteredPosts);
            toggleSpinner(false);
        }
    };

    const fetchCateredPosts = async () => {
        let term = searchParams.get("q");
        let filters = searchParams.get("filter");
        const sort = searchParams.get("sort");
        console.log("fetch catered posts")

        if (!filters) {
            filters = "";
        }
        if (!term) {
            const res1 = await fetch(
                `${
                    process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                }/posts/listtest?limit=5${
                    tokenStateRef.current
                        ? "&nextPageToken=" + tokenStateRef.current
                        : ""
                }${sort ? "&sort=" + sort : ""}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    // mode: "cors",
                    body: JSON.stringify({
                        includeIndustries: [],
                        excludeIndustries: [],
                        excludePosts: [],
                        user: props.user ? props.user.username : "",
                    }),
                }
            );
            const newPosts = ((await res1.json()?.posts) || []).slice(0, 5);
            if (!newPosts) return;
            const newLimit = 50;

            const res2 = await fetch(
                `${
                    process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                }/posts/listtest?limit=${newLimit}${
                    tokenStateRef.current
                        ? "&nextPageToken=" + tokenStateRef.current
                        : ""
                }${sort ? "&sort=" + sort : ""}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    // mode: "cors",
                    body: JSON.stringify({
                        includeIndustries: [],
                        excludeIndustries: [],
                        excludePosts: newPosts.map((post) => post._id),
                    }),
                }
            );
            const posts = await res2.json();

            setPageToken(posts.nextPageToken);
            setPosts([...newPosts, ...posts.posts]);
        } else {
            console.log(
                `${
                    process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                }/posts/search?term=${term}&filters=${filters}`
            );
            const res = await fetch(
                `${
                    process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
                }/posts/search?term=${term}&filters=${filters}`,
                {
                    credentials: "include",
                }
            );
            const json = await res.json();

            if (json) {
                setPageToken(json.nextPageToken);
                setPosts(json.posts || []);
                toggleSpinner(false);
            }
        }
    };

    const changePage = (list) => {
        setPosts([]);
        setListState(list);
        setPageToken("");
        const elem = document.getElementById("ProblemList");
        elem.scrollIntoView();
    };

    const fetchPostsByTopic = async () => {
        const sort = searchParams.get("sort");
        const topic = location.pathname.replaceAll("/", "");

        const res = await fetch(
            `${
                process.env.REACT_APP_BACKEND_URL || "http://localhost:2001"
            }/posts/listtest?limit=50${
                tokenStateRef.current
                    ? "&nextPageToken=" + tokenStateRef.current
                    : ""
            }${sort ? "&sort=" + sort : ""}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                // mode: "cors",
                body: JSON.stringify({
                    includeTopics: [topic],
                    excludeTopics: [],
                    excludePosts: [],
                    user: props.user ? props.user.username : "",
                }),
            }
        );

        const posts = await res.json();

        setPageToken(posts.nextPageToken);
        setPosts(posts.posts);
        toggleSpinner(false);
    };

    const fetchPosts = async () => {
        if (
            !location.pathname ||
            location.pathname === "/" ||
            searchParams.get("q")
        ) {
            fetchCateredPosts();
        } else {
            fetchPostsByTopic();
        }
    };

    const getTitle = () => {
        switch (listState) {
            case 0:
                return "For you";
            case 1:
                return "Trending";
            case 2:
                return "New";
            case 3:
                return "Top";
            default:
                return "";
        }
    };

    useEffect(() => {
        setPageToken("");
        setTimeout(() => {
            setPosts([]);
            fetchPosts();
        }, 100);
        if (location.pathname !== "/" && listState === 0) {
            setListState(1);
        }
    }, [location]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    });

    return (
        <div id="ProblemList" className="p-3">
            <h1 className="display-5">{getTitle()}</h1>
            <div className="sort-bar">
                <ul>
                    {location.pathname === "/" ? (
                        <li>
                            <Link
                                onClick={(e) => changePage(0)}
                                className={
                                    listState === 0 ? "active" : "bar-item"
                                }
                                to={location.pathname}
                            >
                                For you
                            </Link>
                        </li>
                    ) : (
                        <></>
                    )}
                    <li>
                        <Link
                            onClick={(e) => changePage(1)}
                            className={listState === 1 ? "active" : "bar-item"}
                            to={location.pathname}
                        >
                            Trending
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={(e) => changePage(2)}
                            className={listState === 2 ? "active" : "bar-item"}
                            to={location.pathname + "?sort=createTime"}
                        >
                            New
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={(e) => changePage(3)}
                            className={listState === 3 ? "active" : "bar-item"}
                            to={location.pathname + "?sort=likes"}
                        >
                            Top
                        </Link>
                    </li>
                </ul>
            </div>
            {posts.map((post) => (
                <Problem
                    post={post}
                    key={post._id}
                    user={props.user}
                    fetchPosts={fetchPosts}
                />
            ))}
            <div
                className={
                    showSpinner
                        ? "spinner spinner-border text-primary"
                        : "d-none"
                }
                role="status"
            >
                <span class="sr-only">Loading...</span>
            </div>
            {(!posts || !posts.length) && !showSpinner ? (
                <div className="row no-content-container">
                    <div className="col-md-6">
                        <img
                            id="no-content-image"
                            src="https://img.freepik.com/free-photo/big-cat-chair_1385-1504.jpg?t=st=1733433240~exp=1733436840~hmac=57d36e8ecac3afbfd5ffcb3b9669b8eff75b56c65da00fdd1aee455364212f9c&w=1060"
                            alt="cat"
                        ></img>
                    </div>
                    <div className="col-md-6">
                        <div className="centered-content-holder">
                            <h1 className="display-5">Oops!</h1>
                            <p className="lead">
                                We didn't find any posts. Try changing your
                                request.
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <br></br>
            <br />
            <br />
        </div>
    );
}

export default ProblemList;
